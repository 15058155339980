import dynamic from 'next/dynamic';

import Button from 'shopper/components/Button';
import Text from 'shopper/components/Text';

import useDialog from 'hooks/useDialog';

import placeholder from 'lib/placeholder';

import { useUser } from 'providers/UserProvider';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const WhatIsPromobitDescription = () => {
  const { showDialog } = useDialog();
  const { isLoggedIn } = useUser();

  return (
    <>
      <Text
        className="mb-4 text-balance text-base lg:text-sm lg:leading-6"
        size="size1"
      >
        {placeholder('descriptions.whatIsPromobit')}
      </Text>
      {!isLoggedIn && (
        <Button
          size="size2"
          type="secondary"
          full
          onClick={() => {
            showDialog(LoginDialog);
          }}
        >
          {placeholder('actions.signupShort')}
        </Button>
      )}
    </>
  );
};

export default WhatIsPromobitDescription;
